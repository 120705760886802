<template>
  <div>
    <HeaderPanel
      title="SALES CHANNEL"
      :filter="filter"
      placeholder="Search Sales Channel Name"
      @search="getList"
      :hasFilter="false"
      :hasDropdown="false"
      routerPath="/saleschannel/details/0"
    />
    <div class="bg-white border-red px-1 px-sm-3 pb-3 mt-3">
      <b-row class="mt-3">
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(name)="data">
              <div>
                <router-link :to="'/saleschannel/details/' + data.item.id">
                  <b-button variant="link" class="name-link">
                    {{ data.item.name }}
                  </b-button>
                </router-link>
              </div>
            </template>
            <template v-slot:cell(image_url)="data">
              <div
                class="box-image"
                v-bind:style="{
                  'background-image': 'url(' + data.item.image_url + ')',
                }"
                v-if="data.item.is_video != 1"
              ></div>
              <div
                v-else
                class="box-video embed-responsive embed-responsive-16by9 yt-box"
              >
                <video class="w-100 videos" controls>
                  <source :src="data.item.image_url" type="video/mp4" />
                </video>
              </div>
            </template>
            <template v-slot:cell(id)="data">
              <div class="d-flex justify-content-center">
                <router-link :to="'/saleschannel/details/' + data.item.id">
                  <b-button variant="link" class="text-warning px-1 py-0">
                    <font-awesome-icon icon="pencil-alt" title="Edit" />
                  </b-button>
                </router-link>
                <b-button
                  variant="link"
                  class="text-danger px-1 py-0"
                  @click="AlertDelete(data.item.id)"
                >
                  <font-awesome-icon
                    icon="trash-alt"
                    title="Delete"
                    class="main-color"
                  />
                </b-button>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>
  </div>
</template>

<script>
import HeaderPanel from "@/components/HeaderPanel";
import Pagination from "@/components/Pagination";
export default {
  components: {
    HeaderPanel,
    Pagination,
  },
  name: "SalesChannelIndex",
  data() {
    return {
      fields: [
        {
          key: "image_url",
          label: "Thumbnail",
          class: "w-200",
        },
        {
          key: "name",
          label: "Sale Channel Name",
        },
        {
          key: "id",
          label: "Action",
        },
      ],
      items: [],
      isLoadingData: false,
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      filter: {
        search: "",
        take: 10,
        page: 1,
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
    };
  },
  created: async function () {
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      await this.$store.dispatch("ActionGetSalesChannel", this.filter);
      var data = await this.$store.state.moduleSalesChannel
        .stateGetSalesChannel;
      if (data.result == 1) {
        this.items = data.detail;
        this.rows = data.detail.length;
        this.isBusy = false;
      }
    },

    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    AlertDelete(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this item!",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.deleteData(id);
        }
      });
    },
    deleteData: async function (id) {
      this.isLoadingData = true;
      await this.$store.dispatch("ActionDeleteSalesChannel", id);
      var data = await this.$store.state.moduleSalesChannel
        .stateDeleteSalesChannel;
      if (data.result == 1) {
        this.isLoadingData = false;
        this.$swal("Your item has been deleted!", {
          icon: "success",
        }).then(function () {
          location.reload();
        });
      }
    },
  },
};
</script>

<style scoped></style>
